






























// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
import { sum } from 'lodash';
// Import components -------------------------------------------------------------------------------
import FormFieldText from './FormFieldText.vue';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { FormFieldText as TFormFieldText } from '@/utils/forms.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'FormFieldPassword',
  components: { FormFieldText },
  props: {
    field: {
      type: Object as PropType<TFormFieldText>,
      required: true
    }
  },
  setup(properties, { root }) {
    /**
     * Password requirements must be sufficiently strict
     */
    const passwordRequirements = computed(() => ({
      length: {
        label: root.$t('commons.sentences.at-least-characters--s', { value: 8 }),
        valid: properties.field.value.length >= 8
      },
      number: {
        label: root.$t('commons.sentences.must-contain-number--s'),
        valid: /\d/gm.test(properties.field.value)
      },
      uppercase: {
        label: root.$t('commons.sentences.must-contain-uppercase--s'),
        valid: /[A-Z]/gm.test(properties.field.value)
      },
      special: {
        label: root.$t('commons.sentences.must-contain-special--s'),
        valid: /[\^$*.[\]{}()?"!@#%&-+/\\,><':;|_~`]/gm.test(properties.field.value)
      },
      lowercase: {
        label: root.$t('commons.sentences.must-contain-lowercase--s'),
        valid: /[a-z]/gm.test(properties.field.value)
      }
    }));

    const passwordRequirementsScore = computed(() => {
      return sum(
        Object.values({
          length: properties.field.value.length < 8 ? properties.field.value.length * 7.5 : 60,
          number: passwordRequirements.value.number.valid ? 10 : 0,
          uppercase: passwordRequirements.value.uppercase.valid ? 10 : 0,
          special: passwordRequirements.value.special.valid ? 10 : 0,
          lowercase: passwordRequirements.value.lowercase.valid ? 10 : 0
        })
      );
    });

    const progressColor = computed(() => {
      if (passwordRequirementsScore.value >= 100) {
        return 'success';
      }

      if (passwordRequirementsScore.value >= 60) {
        return 'warning';
      }

      return 'error';
    });

    const isNotStrongEnough = computed(() => {
      return passwordRequirementsScore.value !== 100;
    });

    return {
      // Values
      passwordRequirements,
      passwordRequirementsScore,
      progressColor,
      // Flags
      isNotStrongEnough
    };
  }
});
